/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import './src/styles/global.css'

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `Questa applicazione è stata aggiornata. ` +
        `Vuoi ricaricare la pagina per ottenere l'ultima versione?`
    )
  
    if (answer === true) {
      window.location.reload()
    }
  }