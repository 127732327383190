module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"wimo","short_name":"wimo","start_url":"/?utm_source=pwa&utm_medium=pwa&utm_campaign=pwa","background_color":"#111111","lang":"it_IT","description":"wimo, tapas artiginali e vini naturali, il nostro tesoro nascosto in provincia","theme_color":"#111111","display":"standalone","icon":"src/images/wimo-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b439a9b8917f797f57aa1636b66ff899"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
