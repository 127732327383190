exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bevande-js": () => import("./../../../src/pages/bevande.js" /* webpackChunkName: "component---src-pages-bevande-js" */),
  "component---src-pages-carta-dei-vini-js": () => import("./../../../src/pages/carta-dei-vini.js" /* webpackChunkName: "component---src-pages-carta-dei-vini-js" */),
  "component---src-pages-cocktail-list-js": () => import("./../../../src/pages/cocktail-list.js" /* webpackChunkName: "component---src-pages-cocktail-list-js" */),
  "component---src-pages-evento-speciale-js": () => import("./../../../src/pages/evento-speciale.js" /* webpackChunkName: "component---src-pages-evento-speciale-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-fine-anno-js": () => import("./../../../src/pages/menu-fine-anno.js" /* webpackChunkName: "component---src-pages-menu-fine-anno-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */)
}

